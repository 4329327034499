var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fabrics-container rounded elevation-3",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',[_vm._v("Fabrics")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex flex-row justify-end align-base-line"},[_c('search-box',{attrs:{"disabled":_vm.loading},on:{"on-search":function($event){return _vm.fetchFabrics(true)}},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.showForm()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.fabricHeaders,"items":_vm.fabrics,"items-per-page":-1,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"ml-4",attrs:{"true-value":"active","false-value":"inactive"},on:{"change":function($event){return _vm.checkStatusInactive($event, item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.imageUrls.length)?_c('gw-product-image',{staticClass:"cursor-pointer py-3",attrs:{"src":item.imageUrls[0]},on:{"click":function($event){return _vm.previewImg(item.imageUrls[0])}}}):_vm._e()]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.size || '')+" ")]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.color || '')+" ")]}},{key:"item.unitPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.unitPrice))+" "+_vm._s(item.currency.toUpperCase())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.editFabric(item.id)}}},[_c('v-list-item-title',[_vm._v(" Edit ")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.modalDeleteFabric(item.id)}}},[_c('v-list-item-title',[_vm._v(" Delete ")])],1)],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center mt-5"},[_c('v-pagination',{attrs:{"length":_vm.filters.pages,"total-visible":7,"disabled":_vm.loading},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1),_c('div',[_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v("หมายเหตุ")])]),_c('v-card-text',[_c('v-form',{ref:"statusNote"},[_c('v-textarea',{attrs:{"label":"หมายเหตุ","dense":"","hide-details":"","rules":_vm.textBoxRules,"outlined":""},model:{value:(_vm.dataDialog.statusNote),callback:function ($$v) {_vm.$set(_vm.dataDialog, "statusNote", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"dataDialog.statusNote"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateStatusFabric(_vm.dataDialog)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1),_c('img-popup-preview',{attrs:{"img-src":_vm.currentImgPreview},model:{value:(_vm.previewEnabled),callback:function ($$v) {_vm.previewEnabled=$$v},expression:"previewEnabled"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }