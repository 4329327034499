import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class FabricsProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MER_API)
  }

  getAll (query) {
    this.setHeader(getAuthToken())
    return this.get('/fabrics', query)
  }

  getById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/fabrics/${id}`)
  }

  getHistoryById (id) {
    this.setHeader(getAuthToken())
    return this.get(`fabrics/histories/${id}`)
  }

  createOne (form) {
    this.setHeader(getAuthToken())
    return this.post('/fabrics', form)
  }

  updateOne (id, form) {
    this.setHeader(getAuthToken())
    return this.put(`/fabrics/${id}`, form)
  }

  deleteOne (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/fabrics/${id}`)
  }
}

export default FabricsProvider
